.functional-button {
    $block-name: &;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid $gray;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s box-shadow ease-in-out;
    &:hover {
        box-shadow: 0 0 5px rgba(0,0,0,20%);
    }
    &__icon {
        font-size: 18px;
        color: $gray;
    }
    &--like,
    &--dislike {
        #{$block-name} {
            &__icon {
                color: $white;
            }
        }
    }
    &--like {
        border-color: $blue;
        background-color: $blue;
    }
    &--dislike {
        border-color: $maroon;
        background-color: $maroon;
    }
    &--align-bottom {
        align-items: flex-end;
    }
}
