*,
*::before,
*::after {
    box-sizing: border-box;
}

ul[class],
ol[class] {
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

ul[class],
ol[class] {
    list-style: none;
}

body {
    min-height: 100%;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.mb-4 {
    margin-bottom: 40px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mt-4 {
    margin-top: 40px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 10px !important;
}

.pt-2 {
    padding-top: 20px !important;
}

.pt-3 {
    padding-top: 30px !important;
}

.pt-4 {
    padding-top: 40px !important;
}

.container-holder {
    display: block;
    margin: 0 auto;
    padding: 0 15px;
    @media all and (min-width: $m) {
        padding: 0 30px;
    }
    @media all and (min-width: $l) {
        max-width: 1240px;
    }
}

.container__title {
    margin-bottom: 29px;
    font-family: $avant;
    font-size: 26px;
    line-height: 1.42;
    font-weight: 700;
    color: $white;
    text-align: center;
    @media (min-width: $s) {
        font-size: 26px;
    }
}

.flex {
    .container-holder {
        @media all and (min-width: $m) {
            display: flex;
        }
    }
    &.align-center {
        .container-holder {
            @media all and (min-width: $m) {
                align-items: center;
            }
        }
    }
    &.justify-space-between {
        .container-holder {
            @media all and (min-width: $m) {
                justify-content: space-between;
            }
        }
    }
    &.justify-end {
        .container-holder {
            @media all and (min-width: $m) {
                justify-content: flex-end;
            }
            .lp__column-l {
                @media all and (min-width: $m) {
                    margin-right: 120px;
                }
            }
        }
    }
    &.reverse {
        .container-holder {
            display: flex;
            @media all and (max-width: $m - 1) {
                flex-direction: column-reverse;
            }
            @media all and (min-width: $m) {
                flex-direction: row-reverse;
            }
        }
    }
}

.lp {
    &__summary-image {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        img {
            max-height: 450px;
        }
        @media (min-width: $xs) and (max-width: $s - 1) {
           img {
               max-height: 300px;
           }
        }
        @media (min-width: $s) and (max-width: $m - 1) {
            img {
                max-height: 350px;
            }
        }
    }

    &__column-l {
        @media all and (min-width: $m) {
            width: 50%;
        }
    }
    &__column-r {
        @media all and (min-width: $m) {
            width: 50%;
        }
    }
    &__column-r,
    &__column-l {
        &--width-desktop {
            @media all and (min-width: $m) {
                flex-shrink: 0;
                width: 500px;
            }
        }
        &--width-desktop-big {
            @media all and (max-width: $m - 1) {
                display: flex;
                justify-content: flex-end;
            }
            @media all and (min-width: $m) {
                flex-shrink: 0;
                width: 35%;
            }
            @media all and (min-width: $xl) {
                width: 480px;
            }
            .lp__image {
                @media (min-width: $xs) and (max-width: $s - 1) {
                    width: 85%;
                }
                @media (min-width: $s) and (max-width: $m - 1) {
                    width: 66%;
                }
                @media all and (max-width: $m - 1) {
                    margin-right: -15px;
                }
            }
        }
        &--increased {
            @media all and (min-width: $m) {
                width: 55%;
            }
        }
    }
    &__content {}
    &__title {
        margin: 0 0 20px;
        font-family: $avant;
        font-size: 26px;
        font-weight: bold;
        line-height: 1.4;
        color: $blue;
        @media all and (min-width: $m) {
            margin: 0 0 20px;
            font-size: 26px;
        }
        &--h1 {
            font-size: 32px;
            margin: 0 0 25px;
            @media all and (min-width: $s) {
                margin: 0 0 42px;
            }
            @media all and (min-width: $l) {
                font-size: 40px;
                margin: 0 0 30px;
            }
        }
        &--h1--without-margin {
            font-size: 32px;
            margin: 0;
            @media all and (min-width: $l) {
                font-size: 40px;
            }
        }

        &--white {
            color: $white;
        }
    }
    &__text {
        font-family: $avenir-next;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.6;
        color: $blue;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &--big {
            font-size: 20px;
            line-height: 1.4;
            @media all and (min-width: $xl) {
                font-size: 26px;
            }
        }
        &--white {
            color: $white;
        }
        &--bold {
            font-weight: 600;
        }
    }

    &__grocery-image {
        border: 3px solid #00315d;
        border-radius: 0 30px 30px 0;
        position: relative;

        &__image {
            border-radius: 0 25px 25px 0;
            opacity: 0.7;
            filter: blur(1.5px);
        }

        &__text {
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            bottom: 0;
            top:0;
            font-family: $avant;
            font-size: 18px;
            line-height: 1.38;
            font-weight: 700;
            color: $blue;
            padding: 30px;

            @media (max-width: $xs) {
                font-size: 13px;
                padding: 15px;
            }
            p {
                background: $blue;
                color: white;
                padding: 5px 5px 5px 10px;
                margin-bottom: 15px;
                border-radius: 0 12px 12px 0;

                sup {
                    font-weight: normal !important;
                }
            }

            @media (min-width: $xs) {
                p {
                    padding: 15px 15px 15px 20px;
                    margin-bottom: 30px;

                    sup {
                        font-weight: normal !important;
                    }
                }
            }
        }
    }

    &__image {
        position: relative;
        &-text {
            position: absolute;
            font-family: $avant;
            font-size: 18px;
            line-height: 1.38;
            font-weight: 700;
            color: $white;
            text-align: center;
            @media (min-width: $s) {
                font-size: 24px;
            }
        }
        &-text-below {
            font-family: $avenir-next;
            font-size: 18px;
            line-height: 1.38;
            font-weight: 700;
            text-align: center;
            color: $blue;
            letter-spacing: 1px;
            @media (min-width: $s) {
                font-size: 20px;
            }
            @media (min-width: $m) {
                padding-top: 20px;
            }
        }
        &--left {
            @media all and (min-width: $m) {
                display: flex;
                justify-content: flex-start;
            }
        }
        &--center {
            @media all and (min-width: $m) {
                display: flex;
                justify-content: center;
            }
        }
        &--right {
            @media all and (min-width: $m) {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    &__button {
        display: inline-block;
        height: 40px;
        border-radius: 8px;
        padding: 11px 40px;
        font-family: $avant;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.4;
        text-decoration: none;
        text-transform: uppercase;
        color: $white;
        background-color: $indigo;
        outline: none;
        transition: 0.2s background-color ease-in-out;
        &:hover {
            background-color: $green;
        }
        &--green,
        &--maroon {
            color: $white;
            @media all and (min-width: $m) {
                min-width: unset;
                border-radius: 12px;
            }
        }
        &--green {
            background-color: $green;
            border: 1px solid $green;
        }
        &--maroon {
            background-color: $maroon;
            border: 1px solid $maroon;
            transition: 0.2s border-color ease-in-out;
            &:hover {
                border-color: $green;
            }
        }
    }
}

.carousel {
    &__prev,
    &__next {
        width: 20px;
        height: 60px;
        border-radius: unset;
        background-color: transparent;
        background-image: url("/images/landing/right-arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        outline: none;
    }
    &__next {
        right: 10px;
    }
    &__prev {
        margin-top: -30px;
        transform: rotate(180deg);
    }
    &__icon {
        display: none;
    }
    &__prev--in-active,
    &__next--in-active {
        display: none;
    }
}

.bg-green {
    background-color: $green;
}

.landing-holder {
    background-color: $white;

    // Navbar start
    .navbar {
        display: flex;
        &__item {
            display: inline-block;
            text-decoration: none;
            font-family: $avant;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.2;
            color: $indigo;
            transition: 0.2s color ease-in-out;
            @media all and (max-width: $m) {
                font-size: 12px;
            }
            &:not(:last-child) {
                margin-right: 15px;
                @media all and (min-width: $xs) {
                    margin-right: 30px;
                }
            }
            &:hover {
                color: $green;
            }
        }
    }
    // Navbar end

    // Intro start
    .intro {
        padding: 27px 0 0;
        background-color: $blue;
        @media all and (min-width: $s) {
            padding: 27px 0 0;
        }
        @media all and (min-width: $m) {
            padding: 80px 0 0;
        }
        .lp {
            &__column-l {
                @media (min-width: $s) and (max-width: $m - 1) {
                    max-width: 580px;
                }
            }
            &__content {
                margin-bottom: 10px;
                @media all and (min-width: $m) {
                    margin-bottom: 260px;
                }
            }
            &__image {
                &-i {
                    position: relative;
                    z-index: 5;
                }
            }
        }

    }
    // Intro end

    // Description-second start
    .description-second {
        background-color: $white;
        margin-top: -145px;
        padding: 175px 20px 60px;

        @media all and (min-width: $xs) {
            margin-top: -230px;
            padding-top: 290px;
        }

        @media all and (min-width: $m) {
            margin-top: -230px;
            padding-top: 84px;
        }
        @media all and (min-width: $l) {
            margin-top: -195px;
            padding-top: 50px;
        }
        .reference {
            width: 100%;
            margin: auto;
            padding-top: 20px;

            @media (min-width: $l) {
                width: 1200px;
            }

            &__divider {
                margin: 0 !important;
            }

            &__list {
                font-size: 12px;
                list-style: auto;
                padding-left: 30px;
                margin-top: 10px;
                color: $blue;
                font-weight: bold;
            }
        }

        &__button {
            height: unset;
            line-height: 1.4;
            padding: 9px 20px 5px;
            max-width: 200px;
            @media (min-width: $s) and (max-width: $m - 1) {
                max-width: unset
            }
            @media all and (min-width: $m) {
                max-width: 200px;
            }
        }
        &__pseudo-button {
            display: block;
            max-width: 200px;
            margin: 0 auto;
            border-radius: 10px;
            padding: 9px 20px 5px;
            line-height: 1.4;
            color: $white;
            background-color: $maroon;
            @media (min-width: $s) and (max-width: $m - 1) {
                max-width: 300px;
            }
            @media all and (min-width: $m) {
                max-width: 200px;
            }
        }
        .lp {
            &__column-l {
                @media all and (min-width: $m) {
                    width: 55%;
                    margin-left: 40px;
                    margin-top: 0;
                }

                margin-top: 40px;
            }
            &__column-r {
                @media (min-width: $s) and (max-width: $m - 1) {
                    display: flex;
                }
            }
            &__image {
                @media all and (max-width: $m - 1) {
                    margin-bottom: 40px;
                }
                @media all and (max-width: $xs) {
                    margin: 0 -15px;
                }
                @media (min-width: $xs + 1) and (max-width: $m - 1) {
                    padding: 0 15px;
                }
                @media (min-width: $s) and (max-width: $m - 1) {
                    width: 90%;
                }
            }
            &__image-text {
                @include position(absolute, 50%, 10%, null, 10%);
                transform: translate(0%, -50%);
                @media (min-width: $xs) {
                    @include position(absolute, 50%, 12%, null, 12%);
                    transform: translate(0%, -50%);
                    font-size: 24px;
                }

                @media (min-width: $s) {
                    @include position(absolute, 50%, 10%, null, 10%);
                    font-size: 24px;
                }

                @media (min-width: $m) {
                    @include position(absolute, 50%, 8%, null, 5%);
                    transform: translate(0%, -50%);
                }

                @media (min-width: $xl) {
                    font-size: 20px;
                }

                strong {
                    display: block;
                    font-family: inherit;
                    color: $yellow;
                    font-size: 22px;
                    font-weight: 700;
                    margin-bottom: 20px;
                    @media (min-width: $xs) {
                        font-size: 28px;
                    }
                    @media (min-width: $s) {
                        font-size: 32px;
                    }
                    @media (min-width: $m) {
                        font-size: 26px;
                    }
                }

                span {
                    display: block;
                    margin-bottom: 20px;
                    font-family: inherit;
                    color: $yellow;
                    @media (min-width: $m) {
                        font-size: 20px;
                    }
                }
            }
            &__content {
                padding-top: 20px;
            }
            &__text {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.4;
                margin-bottom: 55px;

                @media (max-width: $m) {
                    margin-bottom: 20px;
                }

                sup {
                    font-size: 13px;
                }
                &-footnote {
                    padding-left: 30px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.4;
                    color: $green;
                }
            }
        }
    }
    .text-color-secondary {
        color: $green;
    }
    // Description-second end

    // Description-first start
    .description-first {
        position: relative;


        .container-holder {
            margin: 0 auto;
            padding: 0 15px;
            @media (max-width: $s) {
                padding: 0;
            }
            @media (min-width: $s - 1) and (max-width: $m - 1) {
                padding: 0;
            }
        }

        &__right-background {
            width: 40vw;
            background: $green;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;

            @media (max-width: $s) {
                display: none;
            }
            @media (min-width: $s - 1) and (max-width: $m - 1) {
                display: none;
            }
            //@media (max-width: $l) {
            //    display: none;
            //}
        }

        &__right-background-round {
            width: 21vw;
            border-radius: 49%;
            background: #32CD99;
            position: absolute;
            right: 39%;
            top: -60px;
            bottom: -60px;
            transform: translateX(50%);
            @media (max-width: $m - 1) {
                display: none;
            }
        }
        &__content {
            position: relative;
            overflow: hidden;
            @media (max-width: $m - 1) {
                padding: 0;
            }
        }

        .lp {
            &__column-l {
                width: 50%;
                z-index: 1;
                position: relative;

                @media (max-width: $m - 1) {
                    width: 100%;
                    background: $green;
                }
            }
            &__column-r {
                width: 50%;
                z-index: 1;
                @media (max-width: $m - 1) {
                    width: 100%;
                }
            }
            &__image {
                @media all and (max-width: $xs -1) {
                    margin-bottom: 40px;
                }
                @media (min-width: $xs) and (max-width: $m - 1) {
                    margin-bottom: 60px;
                }
                @media (min-width: $s) and (max-width: $m - 1) {
                    max-width: 70%;
                }
            }
            &__ss-image {
                position: absolute;
                top: -25px;
                right: 100px;
                max-width: 300px;
                z-index: 2;
                @media  (max-width: $m - 1) {
                    display: none;
                }

                @media (min-width: $m) and (min-width: $l) {
                    max-width: 350px;
                }

                @media  (min-width: $xl) {
                    max-width: 450px;
                }
            }

            &__ss-image-mobile {
                position: absolute;
                top: -25px;
                right: 30px;
                max-width: 220px;
                z-index: 2;
                display: none;
                @media  (max-width: $m - 1) {
                    display: unset;
                }
            }

            &__content {
                color: white;
                font-weight: bold;
                padding-left: 70px;
                font-size: 24px;

                @media (max-width: $s) {
                    font-size: 20px;
                    padding: 40px 0;
                }

                @media (min-width: $s) and (max-width: $m - 1) {
                    margin-bottom: 10px;
                }
            }

            &__content-r {
                color: white;
                font-weight: bold;
                padding: 50px 15px;
                font-size: 20px;
                @media (max-width: $s) {
                    padding-top: 0;
                }
            }

            &__image-text {
                @include position(absolute, 15%, 5%, null, 5%);
                letter-spacing: 1px;

                @media (min-width: $s) {
                    @include position(absolute, 25%, 15%, null, 15%);
                    font-size: 20px;
                }

                @media (min-width: $m) {
                    @include position(absolute, 25%, 15%, null, 15%);
                }

                @media (min-width: $l) {
                    @include position(absolute, 15%, 5%, null, 5%);
                }

                strong {
                    display: block;
                    font-family: inherit;
                    font-size: 80px;
                    font-weight: 700;
                    font-style: normal;

                    @media (min-width: $xl) {
                        font-size: 80px;
                    }
                }
            }
        }
    }
    // Description-first end

    // About start
    .about {
        padding-top: 50px;
        padding-bottom: 60px;
        position: relative;
        z-index: 2;
        .lp__content {
            margin-top: 40px;
        }
        .lp__text {
            @media (min-width: $m) {
                margin-bottom: 52px;
            }
        }
    }
    // About end

    // Benefits-list start
    .benefits-list {
        &__item {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
        &__title {
            display: inline-block;
            margin-bottom: 12px;
            font-family: $avenir-next;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            color: $blue;
        }
    }
    // Benefits-list end

    // Works start
    .works {
        background-image: url('/images/landing/works-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        overflow: hidden;
        @media (min-width: $m) {
            padding-top: 80px;
            padding-bottom: 82px;
        }

        .container-holder {
            position: relative;
            &:after {
                @media (min-width: $m) {
                    content: "";
                    position: absolute;
                    top: -26px;
                    left: 80px;
                    background-image: url("/images/landing/works-img.png");
                    width: 280px;
                    height: 582px;
                    background-position: center center;
                    background-size: contain;
                    @media all and (min-width: $xl) {
                        left: 65px
                    }
                }
            }
        }

        &__title {
            color: $green;
            margin: 3px 0 32px;
            @media (min-width: $m) {
                margin-left: 370px;
                max-width: 580px;
                text-align: left;
            }
            @media (min-width: $xl) {
                margin-left: 350px;
            }
        }

        .works-list {
            padding-bottom: 60px;
            margin-bottom: 0;
            @media (min-width: $m) {
                margin-left: 370px;
                max-width: 580px;
                padding-bottom: 20px;
            }
            @media (min-width: $xl) {
                margin-left: 350px;
            }

            &__item {
                padding-left: 45px;
                font-family: $avenir-next;
                font-size: 20px;
                line-height: 1.2;
                font-weight: normal;
                color: $blue;
                display: flex;
                justify-content: flex-start;
                position: relative;
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
                @media (min-width: $m) {
                    padding-left: 55px;
                    font-size: 18px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: -7px;
                    left: 0;
                    width: 32px;
                    height: 32px;
                    background-image: url("/images/landing/check-mark.svg");
                }
            }
        }

        .button-holder {
            text-align: center;
        }
    }
    // Works end

    // How it works start
    .how-it-works {
        position: relative;
        background-image: url('/images/landing/how-it-work-bg.jpeg');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 80px;
        @media all and (min-width: $xl) {
            background-position-y: -18px;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black, .6);
        }

        .container-holder {
            position: relative;
            z-index: 1;
        }

        &__title {
            margin-bottom: 40px;
            font-family: $avant;
            font-size: 26px;
            line-height: 1.42;
            font-weight: bold;
            text-align: center;
            color: $white;
            @media all and (min-width: $xl) {
                margin-bottom: 39px;
            }
        }
        .container-holder {
            position: relative;
            z-index: 1;
        }

        .carousel__item {
            position: relative;
            display: block;
            max-width: 200px;
            margin: 0 auto;
            @media all and (min-width: 570px) {
                margin: unset;
            }
            &:before {
                @include size(24px);
                content: '';
                display: block;
                background-color: $green;
                position: relative;
                z-index: 2;
            }
            &:after {
                content: '';
                @include size(100%, 2px);
                @include position(absolute, 11px, 0);
                background-color: $white;
                @media (min-width: 570px) {
                    width: calc(100% + 133px);
                    right: -40px;
                }
                @media (min-width: $xl) {
                    width: calc(100% + 133px);
                    right: -133px;
                }
            }
        }

        .carousel__text {
            min-height: 285px;
            max-height: 285px;
            overflow: hidden;
            margin-top: 21px;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 1.5;
            text-align: left;
            color: $white;
            text-overflow: ellipsis;
            @media (min-width: $s) {
                min-height: 310px;
                max-height: 310px;
            }
            @media (min-width: $l) {
                min-height: 290px;
                max-height: 290px;
            }
        }

        .carousel__image {
            max-width: 464px;
        }
    }
    // How it works end

    // Benefits start
    .benefits {
        padding-top: 60px;
        padding-bottom: 60px;
        @media (min-width: $m) {
            padding-top: 90px;
            padding-bottom: 90px;
        }
        .lp {
            &__image {
                margin-bottom: 40px;
                @media (min-width: $xs) and (max-width: $s - 1) {
                    max-width: 95%;
                    margin-bottom: 60px;
                    margin-left: -45px;
                }
                @media (min-width: $s) and (max-width: $m - 1) {
                    max-width: 66%;
                    margin-left: -45px;
                }
            }
        }
    }
    // Benefits end

    // We help start
    .we-help {
        padding-top: 60px;

        &__container {
            background-image: url('/images/landing/we-help-bg.jpeg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            padding-top: 60px;
            padding-bottom: 60px;
            position: relative;
            z-index: 2;
            @media all and (min-width: $m) {
                padding-top: 80px;
            }
        }

        .title-block {
            position: absolute;
            top: -30px;
            right: 0;
            left: 0;
            display: flex;
            justify-content: center;

            h2 {
                background: $green;
                padding: 20px;
            }
        }

        &__title {
            margin-bottom: 40px;
            font-family: $avant;
            font-size: 26px;
            line-height: 1.42;
            font-weight: bold;
            text-align: center;
            color: $white;
        }

        &__columns {
            @media (max-width: $s - 1) {
                margin-top: 50px;
            }
            @media (min-width: $m) {
                display: flex;
                margin: 0 -20px;
            }
        }

        &__column {
            @media (min-width: $m) {
                width: 33.3333%;
                padding: 0 20px;
            }
        }

        &__column-title {
            font-family: $avant;
            font-size: 20px;
            line-height: 1.38;
            font-weight: 700;
            text-align: center;
            color: #76beff;
            padding-right: 40px;
            @media (max-width: $xs) {
                padding-right: 10px;

            }
            @media (min-width: $xs) {
                font-size: 20px;
            }
            @media (min-width: $m) {
                height: 56px;
            }
            @media (min-width: $xl) {
                font-size: 20px;
            }
        }

        &__text {
            margin: 20px auto 40px;
            font-family: $avenir-next;
            font-size: 18px;
            line-height: 1.6;
            color: $white;
            overflow: hidden;
            @media (min-width: $s) {
                margin-top: 12px;
                font-size: 18px;
            }
        }
    }
    // We help end

    // Testimonials start
    .testimonials {
        padding-top: 60px;
        padding-bottom: 120px;
        text-align: center;
        background-color: $blue;
        @media (min-width: $xs) {
            padding-bottom: 90px;
        }
        @media (min-width: $m) {
            padding-top: 95px;
            padding-bottom: 78px;
        }

        &.second {
            .container-holder {
                position: relative;

                &:before {
                    content: url("/images/landing/testimonials-decor.svg");
                    position: absolute;
                    bottom: 0;
                }
            }
        }

        &.first {
            position: relative;
            background-image: url("/images/landing/testimonials-decor.svg");
            background-position: right bottom;
            background-repeat: no-repeat;
            background-size: 380px 85px;
            @media all and (min-width: $m) {
                background-position: 80% bottom;
            }
        }

        &.second {
            .container-holder {
                &:before {
                    transform: scaleX(-1);
                    left: -10px;
                }
            }
        }

        .carousel {
            margin-bottom: 31px;
            color: $white;
            &__item {
                padding: 0 30px;
                @media (min-width: $s) {
                    padding: 0 60px;
                }
            }
        }

        figure {
            min-height: 250px;
            line-height: 1.38;
            letter-spacing: .2px;
            margin: 0 auto;

            @media (min-width: $m) {
                max-width: 560px;
                min-height: 200px;
            }

            blockquote {
                font-family: $avenir-next;
                font-size: 20px;
                margin-bottom: 30px;
                @media (min-width: ($l + 1)) {
                    margin-bottom: 12px;
                    font-size: 16px;
                    line-height: 1.625;
                    letter-spacing: -0.1px;
                }
            }

            figcaption {
                font-family: $avenir-next;
                font-size: 20px;
                font-weight: 700;

                @media (min-width: $xs) {
                    font-size: 20px;
                }
                @media (min-width: ($l + 1)) {
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: 0.01px;
                }

                cite {
                    display: block;
                    font-family: inherit;
                    font-weight: 400;
                    font-style: normal;
                }
            }
        }

        .rating {
            @include size(180px, 31px);
            display: block;
            margin: 36px auto 0;

            @media (min-width: $xs) {
                @include size(195px, 35px);
                margin: 18px auto 0;
            }

            &__stars {
                @include size(inherit);
                display: block;
                position: relative;
                background-image: url("/images/landing/stars_outline.svg");
                background-repeat: no-repeat;
                background-size: 180px 31px;
                @media (min-width: $xs) {
                    background-size: 195px 35px;
                }

                .fill {
                    background-image: url("/images/landing/stars_fill.svg");
                    background-repeat: no-repeat;
                    background-size: 180px 31px;
                    height: inherit;
                    position: absolute;
                    top: 0;
                    left: 0;
                    overflow: hidden;
                    @media (min-width: $xs) {
                        background-size: 195px 35px;
                    }
                }
            }
        }

        &__text {
            max-width: 580px;
            margin: 0 auto 24px;
            border-top: 2px solid white;
            padding-top: 30px;
            font-family: $avenir-next;
            font-size: 20px;
            font-style: italic;
            line-height: 1.38;
            color: $white;
            @media (min-width: ($l + 1)) {
                max-width: 580px;
                margin: 0 auto 40px;
                border-top: 2px solid white;
                padding-top: 62px;
                font-size: 16px;
                line-height: 1.5;
            }
        }
        &__button {
            min-width: 325px;
        }
    }
    // Testimonials end

    // Footer start
    .footer {
        padding: 45px 0;
        background-color: $blue;
        &__inner {
            display: flex;
            align-items: center;
            flex-direction: column;
            @media all and (min-width: $s) {
                flex-direction: row;
                justify-content: space-between;
            }
        }
        &__nav {
            @media all and (max-width: $s - 1) {
                margin-bottom: 40px;
            }
        }
        &__menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            list-style: none;
            @media all and (min-width: $s) {
                align-items: flex-start;
            }
            &-item {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
            &-link {
                font-family: $avant;
                font-size: 16px;
                font-weight: bold;
                color: $white;
                text-decoration: none;
                transition: 0.2s color ease-in-out;
                &:hover {
                    color: $green;
                }
                &--underline {
                    text-decoration: underline;
                }
            }
        }
        &__logo {
            @media all and (max-width: $s - 1) {
                margin-bottom: 40px;
            }
            &-link {
                display: inline-block;
                width: 180px;
                height: 33px;
                overflow: hidden;
            }
            &-i {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &__copyright {
            font-family: $avenir-next;
            font-size: 14px;
            color: $white;
            @media all and (min-width: $s) {
                font-size: 16px;
            }
        }
    }
    // Footer end

    // Contact Us
    .contact-us {
        .container-holder {
            padding: 40px 15px;

            @media (min-width: $m) {
                padding: 90px 90px 60px;
            }
        }

        .el-form-item {
            margin-bottom: 20px;
            @media all and (min-width: $m){
                margin-bottom: 35px;
            }
        }

        .button-holder {
            margin-top: 5px;
            text-align: center;
            @media all and (min-width: $m){
                margin-top: 40px;
            }
            .el-form-item {
                margin-bottom: unset;
                @media all and (max-width: $xs){
                    width: 100%;
                }
            }
            .lp__button {
                height: 40px;
                min-width: 325px;
                padding: 8px 40px 7px;
                font-size: 16px;
                font-weight: bold;
                color: $white;
                @media all and (max-width: $xs){
                    width: 100%;
                }
                &:hover {
                    color: $white;
                    background-color: lighten($indigo, 10%);
                }
            }
        }
    }

    .adapt-block {
        padding-top: 50px;
        &__title {
            text-align: center;
            @media all and (min-width: $s) {
                max-width: 60%;
                margin: 0 auto;
            }
        }
        &__inner {
            @media all and (min-width: $s) {
                display: flex;
            }
        }
        &__content {
            @media all and (min-width: $s) {
                margin-right: 20px;
                padding-top: 80px;
            }
            &-text {
                font-size: 22px;
                font-style: italic;
                font-weight: 700;
                color: $green;
            }
        }
        &__image {
            width: 90%;
            margin: 0 auto;
            @media all and (min-width: $s) {
                flex-shrink: 0;
                width: 70%;
                margin: unset;
            }
            &-i {
                max-height: 100%;
            }
        }
    }
}
