.alternative-products {
    $block-name: &;
    &__group {
        display: flex;
        flex-wrap: nowrap;
    }
    &__list {
        overflow-x: auto;
        padding-bottom: 10px;
        flex-wrap: wrap !important;

        &::-webkit-scrollbar {
            display: block;
            height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            height: 8px;
            border-radius: 4px;
            background-color: $gray;
            &:hover {
                background-color: lighten($gray, 10%);
            }
        }
        &-item {
            &:not(:last-child){
                margin-right: 10px;
            }
            &.is-checked {
                .product-block__image{
                    border-color: $indigo;
                }
            }
            .el-checkbox, .el-radio {
                &__input {
                    position: absolute;
                    top: 114px;
                    right: 10px;
                    @media all and (min-width: 390px) {
                        top: 134px;
                    }
                    .el-checkbox__inner, .el-radio1__inner {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        border-color: $light-gray;
                        &:after {
                            top: 2px;
                            left: 7px;
                            width: 6px;
                            height: 12px;
                        }
                    }
                    &.is-checked {
                        .el-checkbox__inner, .el-radio__inner {
                            border-color: $indigo;
                        }
                    }
                }
                &__label {
                    margin-bottom: 0;
                    padding-left: 0;
                }
            }
        }
    }
}
