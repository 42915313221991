.word-game {
    min-height: 100vh;
    &__title {
        margin-bottom: 5px;
        font-family: $avenir-next;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.44px;
        color: $dark;
    }
}
