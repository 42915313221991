.function-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px 15px;
    border-bottom: 1px solid $light-gray;
    &__item {
        padding: 0 5px;
        margin-bottom: 15px;
        .el-button--info {
            &:hover {
                .el-button {
                    &__icon-svg {
                        fill: $gray;
                    }
                }
                > span {
                    color: $gray;
                }
            }
            &.active {
                .el-button {
                    &__icon-svg {
                        fill: $indigo;
                    }
                }
                > span {
                    color: $indigo;
                }
            }
        }
    }
    &__content-info {
        padding-bottom: 15px;
    }
}
