.default-text {
    font-family: $avenir-next;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.44px;
    color: $dark;
    &--offset-bottom {
        margin-bottom: 10px;
        &-middle {
            margin-bottom: 20px;
        }
    }
    &--bold {
        font-weight: 600;
    }
    &--align-right {
        text-align: right;
    }
    &--bordered-bottom-white {
        display: inline-block;
        border-bottom: 1px solid $white;
    }
    &--white {
        color: $white;
    }
    &--offset-left {
        margin-left: 10px;
    }
    &--middle-font {
        font-size: 18px;
    }
    &--blue-color {
        color: $blue;
    }
}
