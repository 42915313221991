// Variables
@use 'variables';

@use "~element-plus/theme-chalk/src/index.scss";

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// Fonts
@import 'fonts';

//Mixins
@import 'mixins';

// Custom Styles
@import 'transitions';
@import 'style';
@import 'modules/auth';
@import 'modules/quiz';
@import 'modules/game/index';
@import 'modules/search/index';
@import 'modules/learning-module/index';
@import 'modules/pmo-module/index';
@import 'modules/my-items/index';
@import 'landing/common';
@import 'global-elements/index';
@import 'modules/profile/index';

// Vue3-carousel
@import '~vue3-carousel/dist/carousel.css';
