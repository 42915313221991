.pantry-category {
    &__video {
        position: relative;
        z-index: 2;
        margin-bottom: 15px;
        &-star-count {
            position: absolute;
            z-index: 3;
            top: 10px;
            right: 10px;
            @media all and (min-width: $s) {
                top: 15px;
                right: 15px;
            }
        }
    }
    &__content-section {
        margin-bottom: 20px;
    }
    &__button-section {
        margin-bottom: 15px;
    }
    &__alternatives-product {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    &__page-slider {
        padding: 10px 0;
    }
}
