.g-container .play-sound-container {
    position: relative;
    display: block;
    margin-top: 100px;
}

.g-container .play-sound-container .play-sound {
    position: absolute;
    right: 30px;
    bottom: 10px;
    z-index: 999;
    // cursor: pointer;
    font-size: 48px;
    color: #484baf;
  }

.user-steps-overview--scroll-lock .play-sound-container{
    position: absolute;
    display: block;
    right: calc(15% - 50px);
    bottom: 20px;
    // margin-top: 100px;
}

.user-steps-overview--scroll-lock .play-sound-container .play-sound{
    // position: absolute;
    // right: 30px;
    // bottom: 10px;
    // z-index: 999;
    // cursor: pointer;
    font-size: 48px;
    color: #484baf;
  }