.auth {
    &-page {
        min-height: 100%;
        .el-main {
            max-width: 400px;
            margin: 0 auto;
            padding: 20px 10px 10px;
        }
        .el-card {
            border-color: transparent;
            box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
            &__body{
                padding: 20px 15px;
            }
        }
        &__top {
            margin-bottom: 35px;
        }
        &__image-wrap {
            width: 154px;
            height: 100px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
        }
        &__title {
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            color: $dark;
        }
        &__error-message {
            margin: 15px 0;
            border-radius: 5px;
            padding: 10px 15px;
            font-size: 14px;
            color: #f56c6c;
            background-color: $white;
            box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
            opacity: 0;
            visibility: hidden;
            transition: 0.2s opacity ease-in-out, 0.2s visibility ease-in-out;
            &--show {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &-form {
        &__title {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.4;
            letter-spacing: 0.15px;
            color: $dark;
        }
        .el-form-item {
            &--default {
                &:not(:last-child) {
                    margin-bottom: 27px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .el-form-item__error {
                    max-height: 27px;
                    overflow: hidden;
                }
            }
        }
        .el-row {
            width: 100%;
            .el-col:last-child {
                text-align: right;
            }
        }
        .el-link {
            font-size: 16px;
            line-height: 1.5;
            font-weight: 400;
            text-decoration: underline;
            color: $blue;
            transition: 0.2s color ease-in-out;
            &:hover {
                color: $green;
            }
            &__wrap {
                margin-left: 5px;
            }
            &--dark {
                color: $dark;
            }
        }
        .el-button {
            width: 100%;
        }
    }
}

.companies_logo {
    max-height: 100px !important;
    max-width: 370px !important;
}
