@import "variables";
html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: $avenir-next;
    font-size: 16px !important;
    letter-spacing: 0.44px;
    background: #f8fafc !important;
    #app {
        height: 100%;
        .app {
            height: 100%;
        }
    }
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    &--stretch-height {
        flex-grow: 1;
    }
}

.main-content {
    $local-light-gray: #f0f0f0;
    $block-name: &;
    flex: 1;
    padding-top: 25px;
    background-color: $white;
    @media all and (min-width: $s){
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
    }
    &__text {
        margin-bottom: 20px;
    }
    &--button-bottom-fixed {
        display: flex;
        flex-direction: column;
        .main-content__main {
            padding-bottom: 70px;
        }
        .main-content__bottom {
            position: fixed;
            z-index: 20;
            bottom: 0;
            left: auto;
            right: auto;
            width: 100%;
            max-width: 800px;
            padding: 20px 0;
            background-color: $white;
            &-wrap {
                text-align: center;
            }
        }
    }
    &--search-page {
        padding-top: 15px;
        background-color: $local-light-gray;
        @media all and (min-width: $m) {
            padding-top: 30px;
        }
        #{$block-name} {
            &__search {
                &-box {
                    margin-bottom: 15px;
                }
                &-text {
                    margin: 0 0 20px;
                    text-align: center;
                }
                &-query {
                    font-weight: 600;
                }
            }
            &__image {
                margin-top: 50px;
                &-wrap {
                    width: 180px;
                    height: 180px;
                    overflow: hidden;
                    margin: 0 auto 30px;
                }
                &-i {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &__products {
                &.el-loading-parent--relative {
                    #{$block-name}__image-wrap {
                        display: none;
                    }
                }
            }
        }
        .el-loading-mask {
            background-color: rgba(255,255,255, 0);
        }
    }
    &--offset-bottom {
        padding-bottom: 30px;
    }
    &--offset-bottom-40 {
        padding-bottom: 40px;
    }
    &--offset-bottom-122 {
        padding-bottom: 122px;
    }
    &--no-offset {
        padding: 0;
    }
    &--bg-light-gray {
        background-color: $local-light-gray;
    }
    &--base-layout {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        min-height: 100%;
        .main-content {
            &__top-content-section {
                margin-bottom: 20px;
            }
            &__bottom-content-section {}
            &__button-wrap {
                width: 100%;
                padding: 20px 0;
                text-align: center;
            }
            &__buttons-wrap {
                display: flex;
                padding: 20px 0;
                margin: 0 -5px;
                @media all and (min-width: $xs) {
                    margin: 0 -10px;
                }
            }
            &__button-col {
                width: 50%;
                padding: 0 5px;
                text-align: center;
                @media all and (min-width: $xs) {
                    padding: 0 10px;
                }
            }
        }
    }
    &--robot-fullscreen-message {
        position: relative;
        z-index: 1;
    }
}

.page-title {}

.text-center {
    text-align: center;
    margin: auto;
}

.main-layout {
    &--bg-gradient {
        min-height: 100%;
        background: linear-gradient(0deg, rgba(245, 245, 245, 0.2), rgba(245, 245, 245, 0.2)), linear-gradient(345.35deg, rgba(50, 205, 153, 0.2) -0.6%, rgba(50, 205, 153, 0) 89.02%);
    }
}

.w-100 {
    width: 100%;
}

.robot-dialog {
    $block-name: &;
    &__button {
        padding: 20px 0;
        @media all and (min-width: $xs) {
            text-align: center;
        }
    }
}

.continue-button {
    width: 100%;
    @media all and (min-width: $xs) {
        max-width: 350px;
    }
}

.user-intro {
    &--bordered {
        position: relative;
        z-index: 2000;
        overflow: visible !important;
        &:before {
            content: "";
            position: absolute;
            top: -5px;
            right: -5px;
            bottom: -5px;
            left: -5px;
            border: 2px solid $green;
            border-radius: 10px;
            box-shadow: 0 0 0 10000px rgba(0,0,0,0.3);
            transition: 0.3s box-shadow 0.3s ease-in-out, 0.3s border 0.3s ease-in-out;
        }
    }
    &-animation {
        overflow: hidden;
    }
}

.el-form-item {
    &__label {
        &--floating-label,
        &--input-mask {
            width: 100%;
        }
    }
}

.user-steps-overview {
    &--scroll-lock {
        overflow: hidden;
    }
}
