.product-question {
    $block-name: &;
    &:not(:last-child) {
        margin-bottom: 45px;
    }
    &__text {
        margin-bottom: 10px;
        font-family: $avenir-next;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: 0.44px;
        word-break: break-word;
        color: $dark;
    }
    &__rate {
        width: 100%;
    }
    &__btn {
        @media all and (max-width: $xs) {
            width: 100%;
        }
        min-width: 100px;
        &-section {
            display: flex;
            flex-wrap: nowrap;
            margin: 0 -5px;
            @media all and (min-width: $s) {
                margin: 0 -10px;
            }
        }
        &-wrap {
            padding: 0 5px;
            @media all and (max-width: $xs) {
                width: 50%;
            }
            @media all and (min-width: $s) {
                padding: 0 10px;
            }
        }
    }
    &-wrap {
        margin-bottom: 40px;
    }
    &__satisfied {
        float: left;
    }
    &__dissatisfied {
        float: right;
    }
}
