.scan-button {
    position: fixed;
    right: 16px;
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: $blue;
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.3);
    user-select: none;
    cursor: pointer;
    transition: 0.3s box-shadow ease-in-out;
    &:hover {
        box-shadow: 0 3px 12px 2px rgba(0, 0, 0, 0.45);
    }
    @media all and (min-width: $s) {
        right: 50%;
        margin-right: -384px;
    }
    &__icon {
        width: 24px;
        height: 24px;
        fill: $white;
    }
}
