.buttons-section {
    &__row {
        display: flex;
        justify-content: center;
        margin: 0 -5px 15px;
        &:last-child {
            margin: 0 -5px 0;
            @media all and (min-width: $s) {
                margin: 0;
            }
        }
        @media all and (min-width: $s) {
            align-items: center;
            flex-direction: column;
            margin: 0;
        }
    }
    &__col {
        width: 50%;
        max-width: 400px;
        padding: 0 5px;
        @media all and (min-width: $s) {
            width: 100%;
            margin: 0 0 15px;
            padding: 0;
        }
        &--wide {
            width: 100%;
        }
    }
}
