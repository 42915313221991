.questions-page {
    &__question-module {
        margin-bottom: 15px;
    }
    &__question {
        section {
            .quiz-question {
                &:not(:first-child) {
                    padding-top: 15px;
                }
                & ~ section {
                    .quiz-question {
                        border-top: 1px solid $light-gray;
                        padding-top: 15px;
                    }
                }
            }
        }
    }
    &__question {
        margin-bottom: 15px;
        &--bordered-top {
            border-top: 1px solid $light-gray;
            padding-top: 15px;
        }
    }
    &__quantity-list {
        &--bordered-top {
            margin-bottom: 20px;
            border-top: 1px solid $light-gray;
            padding-top: 15px;
        }
    }
    &__invite {
        padding-top: 20px;
    }
    &__store-search-section {
        width: 100%;
    }
    &__store-search {
        &-text {
            margin-bottom: 15px;
        }
        &-input {
            margin-bottom: 25px;
        }
        &-section {
            margin-bottom: 50px;
        }
    }
    &__store-list {
        &-section {
            width: 100%;
        }
        &-text {
            margin-bottom: 15px;
        }
    }
    &__button-wrap {
        text-align: center;
    }
    .el-form-item {
        &--default {
            margin-bottom: 27px;
            .el-form-item__error {
                max-height: 27px;
                overflow: hidden;
            }
        }
    }
}

.questions-group {
    $block-name: &;
    &__item {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
    &.finish {
        opacity: 0.8;
        #{$block-name}__item {
            &:not(:last-child) {
                margin-bottom: 18px;
            }
        }
        .question-module {
            &__image {
                width: 68px;
                height: 68px;
                margin-right: 17px;
                &:before {
                    width: 60px;
                    height: 60px;
                }
                &:after {
                    width: 52px;
                    height: 52px;
                    top: 4px;
                    left: 4px;
                }
                &-wrap {
                    width: 42px;
                    height: 42px;
                }
            }
            &__check {
                font-size: 20px;
                &-wrap {
                    width: 24px;
                    height: 24px;
                    right: -7px;
                    bottom: -3px;
                }
            }
            &__title {
                font-size: 16px;
            }
        }
    }
}

.question-module {
    $local-light-gray: #e7e7e7;
    $local-light-indigo: #b5b7df;
    $local-grey: #a6a6a6;
    $block-name: &;
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: default;
    &__image {
        position: relative;
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 84px;
        height: 84px;
        margin-right: 21px;
        border-radius: 50%;
        border: 4px solid $local-light-gray;
        background-color: $white;
        &:before,
        &:after {
            content: "";
            position: absolute;
            border-radius: 50%;
        }
        &:before {
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 76px;
            height: 76px;
            background-color: $white;
        }
        &:after {
            z-index: 3;
            top: 5px;
            right: 5px;
            bottom: 5px;
            left: 5px;
            width: 66px;
            height: 66px;
            background-color: $local-light-indigo;
        }

        &-wrap {
            position: relative;
            z-index: 4;
            width: 54px;
            height: 54px;
        }
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__check-wrap {
        position: absolute;
        z-index: 5;
        right: -6px;
        bottom: -2px;
        width: 28px;
        height: 28px;
        border: 2px solid $white;
        border-radius: 50%;
        background-color: $white;
    }
    &__check {
        font-size: 24px;
        color: $green;
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $local-grey;
    }
    &--middle-size {
        #{$block-name}__image {
            width: 61px;
            height: 61px;
            margin-right: 18px;
            border-color: $light-gray;
            &:before {
                width: 53px;
                height: 53px;
            }
            &:after {
                width: 43px;
                height: 43px;
                background-color: $indigo;
            }
            &-wrap {
                width: 31px;
                height: 31px;
            }
        }
        #{$block-name}__title {
            color: $dark;
        }
    }

    &.active {
        cursor: pointer;
        #{$block-name}__image {
            border-color: $light-gray;
            &:after {
                background-color: $indigo;
            }
        }
        #{$block-name}__title {
            color: $dark;
        }
    }
    &.done {
        #{$block-name}__image {
            border-color: $indigo;
            &:after {
                background-color: $indigo;
            }
        }
        #{$block-name}__title {
            color: $dark;
        }
    }
    &.disabled {
        pointer-events: none;
    }
}

.quiz {
    $local-grey-second: #b0b0b0;
    $local-light-grey-second: #f4f4f4;
    &-question {
        margin-bottom: 10px;
        &__text {
            strong {
                font-weight: 700;
            }
            &--light-gray {
                color: $light-gray !important;
            }
            &--dark {
                color: $dark !important;
            }
        }
        &__text,
        &__quiz-rate {
            margin-bottom: 20px;
        }
        &__list {
            &-item {
                margin-bottom: 6px;
                padding: 10px 0;
                &:not(:last-child) {
                    border-bottom: 1px solid $light-gray;
                }
            }
        }
        &__confirm {
            display: flex;
            flex-wrap: wrap;
        }
        &__quote {
            position: relative;
            margin-bottom: 15px;
            border: 1px solid $local-grey-second;
            border-radius: 12px;
            padding: 15px 15px 15px 42px;
            white-space: normal;
            background-color: $local-light-grey-second;
            &:before {
                content: "";
                position: absolute;
                width: 22px;
                height: 16px;
                top: 10px;
                left: 9px;
                background-image: url("/images/quiz/quotes-icon.svg");
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        &__button {
            &-col {
                width: 50%;
                padding: 0 8px;
                text-align: center;
            }
            &-wrap {
                display: flex;
                padding: 12px 0;
                background-color: $white;
            }
            &-section {
                &--fixed-bottom {
                    position: fixed;
                    z-index: 21;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    .g-container {
                        @media all and (min-width: $s) {
                            max-width: 800px;
                        }
                    }
                }
            }
        }
        &__image {
            margin-bottom: 20px;
            &-wrap {
                width: 135px;
                height: 135px;
                overflow: hidden;
                @media all and (min-width: $s) {
                    width: 180px;
                    height: 180px;
                }
            }
            &-i {
                max-width: 100%;
                max-height: 100%;
            }
            &--centered {
                .quiz-question__image-wrap {
                    margin: 0 auto;
                }
            }
        }
        &--range {
            margin-bottom: 30px;
            .quiz-question {
                &__text {
                    margin-bottom: 20px;
                }
                &__range {
                    &-image {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        margin: 0 -15px 5px;
                        &-wrap {
                            width: 120px;
                            height: 120px;
                            overflow: hidden;
                            padding: 30px 15px 0;
                            text-align: center;
                            transition: 0.2s padding ease-in-out;
                            &--custom {
                                width: 130px;
                                height: 130px;
                                margin-bottom: -10px;
                                overflow: visible;
                            }
                            &.active {
                                padding: 0;
                            }
                        }
                        &-i {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    &-input {
                        position: relative;
                        height: unset;
                        padding: 22px 35px;
                        &:before {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            top: 22px;
                            bottom: 0;
                            left: 50%;
                            width: 3px;
                            height: 10px;
                            margin-left: -2px;
                            background-color: $white;
                        }
                        .el-slider {
                            $el-slider-light-indigo: #bbc6ff;
                            $el-slider-blue: #374073;
                            &__runway {
                                height: 10px;
                                border-radius: 5px;
                                background-color: $el-slider-light-indigo;
                                &:before,
                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    width: 3px;
                                    height: 10px;
                                    background-color: $white;
                                }
                                &:before {
                                    left: 10px;
                                }
                                &:after {
                                    right: 10px;
                                }
                            }
                            &__stop {
                                width: 3px;
                                height: 10px;
                                border-radius: unset;
                                background-color: $white;
                            }
                            &__bar {
                                height: 10px;
                                background-color: transparent;
                            }
                            &__button {
                                width: 44px;
                                height: 44px;
                                border: unset;
                                background-color: $el-slider-blue;
                                box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
                                outline: none;
                                :hover,
                                &.hover,
                                &.dragging {
                                    transform: unset;
                                }
                                &-wrapper {
                                    top: -16px;
                                    width: 44px;
                                    height: 44px;
                                }
                            }
                        }
                        &-wrap {
                            margin-bottom: 10px;
                        }
                    }
                    &-values {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                        margin-bottom: 45px;
                        padding: 0 20px;
                    }
                    &-value {
                        text-align: center;
                        &.active {
                            font-weight: 600;
                        }
                    }
                    &-explanation {
                        max-width: 400px;
                        margin: 0 auto 25px;
                        font-weight: 600;
                        text-align: center;
                        @media all and (min-width: $s) {
                            max-width: 520px;
                        }
                    }
                }
            }
        }
        &--bordered {
            margin-bottom: 25px;
            border-top: 1px solid $light-gray;
            padding-top: 15px;
        }
    }
}

.store-list {
    display: block;
    &__item {
        width: 100%;
        height: unset;
        margin-right: 0;
        border-top: 1px solid $light-gray;
        padding: 11px;
        &:last-child {
            border-bottom: 1px solid $light-gray;
        }
        &.el-checkbox {
            white-space: normal;
            .el-checkbox {
                white-space: normal;
                &__label {
                    flex-grow: 1;
                    margin-bottom: 0;
                    white-space: normal;
                }
                &__input {
                    .el-checkbox__inner {
                        border-color: $gray;
                    }
                }
            }
            &.is-checked {
                background-color: $light-indigo;
                .el-checkbox {
                    &__input {
                        .el-checkbox__inner {
                            border-color: $indigo;
                        }
                    }
                }
            }
        }
    }
}

.notifications-switchers {
    margin-top: 15px;
    label {
        width: 220px;
        display: inline-block;
    }
}
